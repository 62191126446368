import styled from 'styled-components'
import React from "react"

const Wrapper = styled(({ teal, ...props }) => <section {...props} />)`
  background-color: ${props => !props.teal
    ? props.theme.colors.blue
    : props.theme.colors.deepButtonBlue};
  color: ${props => props.theme.colors.lightGrey};
  p {
    color: ${props => props.theme.colors.lightGrey};
  }
  .aqua-blue {
    color: ${props => props.theme.colors.aquaBlue};
  }
  .hero-container {
    margin: 60px auto;
    width: auto;
    img {
      display: none;
    }
  }
  .custom-padding-xs {
    padding: 0 30px 0px;
    p {
      margin: 0 0 40px;
      line-height: 28px;
      font-size:24px;
      letter-spacing: 0.3px;
    }
  }
  h1 {
    margin: 135px 0 40px 0px;
    font-size: 26px;
    font-size: 40px;
    line-height: 44px;
    letter-spacing: 0.5px;
    span {
      color: ${props => props.theme.colors.aquaBlue};
    }
  }

  @media (max-width: ${props => props.theme.responsive.large}) {
    .text-centered h2 {
      padding: 0 30px 10px 30px;
    }
    .hero-container {
      margin: 62px auto;
    }
    .custom-padding-xs {
      padding: 0 30px;
      h1 {
        margin: 80px 0 20px 0px;
        font-size: 26px;
      }
    }
  }

  @media (min-width: ${props => props.theme.responsive.limit }) {
    .custom-padding-xs {
      padding: 0 0 0 15px;
    }
    h1 {
      width: 720px;
    }
    img.hero-image {
      margin: 0px -65px 0 0;  
    }
  }


  @media (max-width: ${props => props.theme.responsive.small}) {
    h2 {
      font-size: 24px;
    }
    h1 {
      font-size: 24px;
      line-height: 28px;
    }
    .custom-padding-xs p {
      font-size: 16px;
      line-height: 20px;
    }
    .hero-container {
      margin: 25px 0;
    }
  }
`

export { Wrapper }
