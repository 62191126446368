import styled from 'styled-components'

const Wrapper = styled.section`
  padding-top: 68px;
  margin-top: 69px;
  background: ${props => props.theme.colors.backgroundGrey};
  .text-centered h2 {
    padding: 0 0 10px;
    color: ${props => props.theme.colors.blue};
  }
  p, h5 {
    color: ${props => props.theme.colors.blue};
  }
  .section-title p {
    font-size: 21px;
    margin-top: 11px;
}
  .row {
    margin-top: 83px;
    &.cards-container{
      .cards{
        margin: 0 0 60px;
        width: 100%;
        .card-container {
          display: flex;
          justify-content: center;
          width: 100%;
          height: 95px;
          img {
            flex: 0 1 auto;
          }
        }
      }
      .cards.integration {
        .card-image {
          max-width: 85px;
          &.integration {
            margin: -30px 0px -30px;
            max-width: 145px;
          }
        }
      }
      .cards {
        margin: 0 0 60px;
        .card-body {
          padding-top: 34px;
          .card-title {
            font-size: 21px;
            font-weight: bold;
            margin: 0 0 20px;
          }
          .card-text {
            max-width: 234px;
            margin: auto;
            font-size: 18px;
            line-height: 20px;
          }
        }
      }
    }
  }

  @media (max-width: ${props => props.theme.responsive.small}) {
    padding: 40px 0;
    margin-top:0px;
    background: ${props => props.theme.colors.white};
    h2 {
      font-size: 24px;
    }
    .section-title p {
      font-size: 16px;
      margin-top: 26px;
    }
    .row {
      margin-top: 11px;
      &.cards-container {
        .cards {
          margin:0;
          .card-container img {
            max-width: 54px;
          }
          .card-body {
            padding: 15px 0 0 0;
            .card-title{
              font-size: 16px;
              line-height: 20px;
              margin: 0 0 12px;
              padding: 0 15px;
            }
            .card-text {
              font-size: 16px;
              line-height: 20px;
              padding: 0 15px;
            }
          }
          &.integration .card-image.integration{
            max-width: 85px;
          }
        }
      }
    }
  }
`

export {Wrapper}
