import React from 'react'
import {Wrapper} from './styles'

import superTransparent from '../../images/super-transparent.gif'
import superSecure from '../../images/super-secure.gif' 
import superSimple from '../../images/super-simple.gif' 
import superService from '../../images/super-service.gif' 

import analyse from '../../images/analyse.gif'
import manage from '../../images/manage.gif'
import mitigate from '../../images/mitigate.gif'
import optimise from '../../images/optimise.gif'


const list = [
    {id: '0', icon: superTransparent, title: 'SUPER TRANSPARENT', text: 'We pride ourselves in our pricing transparency and competitive range. Whatever your business needs, we will deliver the best value.'},
    {id: '1', icon: superSecure, title: 'SUPER SECURE', text: 'We partner with the best technology providers to ensure the highest  security standards in the industry.'},
    {id: '2', icon: superSimple, title: 'SUPER SIMPLE', class: 'super-simple', text: 'Simple and easy on-boarding with a single API means you can start using our complete set of payment tools instantly.'},
    {id: '3', icon: superService, title: 'SUPER SERVICE', text: 'Our experts understand the world of payments. We focus on your needs, always providing you with the solutions you require.'},
]

const tealList = [
    {id: '0', icon: analyse, title: 'ANALYSE', text: '<b>Full transparency</b><span>.</span> ARCH gives you greater visibility of your data. Connecting into your business workflow enables you to make the best decisions.'},
    {id: '1',  icon: manage, title: 'MANAGE', text: '<b>Take control</b><span>.</span> ARCH can help you manage your booking exposure and profit margins.'},
    {id: '2',  icon: mitigate, title: 'MITIGATE', text: '<b>Reduce risk</b><span>.</span> ARCH allows you to customise flexible hedging strategies to suit your business needs.'},
    {id: '3',  icon: optimise, title: 'OPTIMISE', text: '<b>Automated hedging</b><span>.</span> ARCH seamlessly integrates with your business data, enabling you to secure and improve profits.'},
]

const WhiteFourFeatures = ({ teal }) => {
    return (
        <Wrapper>
            <article className={`container ${ teal ? 'tealFourFeatures' : ''}`}>
                {!teal ?
                    <div className="text-centered">
                        <h2>The global payment hub you can trust<span>.</span></h2>
                    </div>
                : 
                    <div className="section-title text-centered">
                        <h2>All your needs<span>.</span> All the time<span>.</span></h2>
                        <p>
                            ARCH is the platform service that gives you solutions and greater<br />
                            visibility to your currency hedging.<br />
                        </p>
                    </div>
                }
                <div className="row cards-container">
                    {!teal ? 
                        list.map(feature => (
                            <div className="col-sm-6 col-xs-12 text-center cards" key={feature.id}>
                                <div className="card-container">
                                    <img src={feature.icon} className={`card-image ${feature.class}`} alt="Card image" />
                                </div>
                                <div className={`card-body ${feature.class}`}>
                                    <h5 className="card-title">{feature.title}</h5>
                                    <p className="card-text" dangerouslySetInnerHTML={{ __html: feature.text }} />
                                </div>
                            </div>
                        ))
                    : 
                        tealList.map(feature => (
                            <div className="col-sm-6 col-xs-12 text-center cards" key={feature.id}>
                                <div className="card-container">
                                    <img src={feature.icon} className={`card-image ${feature.class}`} alt="Card image" />
                                </div>
                                <div className={`card-body ${feature.class}`}>
                                    <h5 className="card-title">{feature.title}</h5>
                                    <p className="card-text" dangerouslySetInnerHTML={{ __html: feature.text }} />
                                </div>
                            </div>
                        ))
                    }
                </div>
            </article>
        </Wrapper>
    );
}

export default WhiteFourFeatures
