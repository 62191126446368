import React from 'react'
import { Link } from 'gatsby'
import {Wrapper} from './styles'

const BlueCallExpert = ({ gateway }) => {
    return (
        <Wrapper gateway={gateway}> 
            <article className={`container ${gateway ? 'gatewayBlueExpert' : ''}`}>
                <div className="row">
                    <div className="col-sm-6">
                        {!gateway ? 
                            <h2>New to payments<span>.</span><br />Want to switch provider<span>.</span></h2>
                        :
                            <h2>
                                Curious?<br />
                                Get your free company
                                analysis now<span>.</span>
                            </h2>
                        }
                    </div>
                    <div className="col-sm-6 custom-padding-xs">
                        {!gateway ?
                            <p>Talk to one of our experts now for<br />a quick and easy switch</p>
                        :
                            <p>
                                See how ARCH helps you with all your currency needs.<br />
                                Talk to one of our currency experts now and receive<br />
                                a customised demo
                            </p>                
                        }
                    </div>
                </div>
                <div className="button-block">
                    <Link className="btn btn-primary aqua" to={gateway ? '/demo' : '/contact'}>Contact an expert</Link>
                </div>
            </article>
        </Wrapper>
    );
}

export default BlueCallExpert
