import React from 'react';

import {Wrapper} from './styles'

import countiresIcon from '../../images/countries-icon.svg'
import currenciesIcon from '../../images/currencies-icon.svg'
import localPayouts from '../../images/local-payouts-icon.svg'
import integrationIcon from '../../images/integration-icon.svg'
import languagesIcon from '../../images/languages-icon.svg'
import customisationIcon from '../../images/customisation-icon.svg'

const list = [
    {id: '0', icon: countiresIcon, title: 'Countries', class: 'countries', text: 'Supported in over 180 countries'},
    {id: '1', icon: currenciesIcon, title: 'Currencies', class: 'currencies', text: 'Process over 150 currencies'},
    {id: '2', icon: localPayouts, title: 'Local Payouts', class: 'local-payouts', text: 'Payouts in 60+ currencies'},
    {id: '3', icon: integrationIcon, title: 'Integration', class: 'integration', text: 'One API. All the tools for a simple solution'},
    {id: '4', icon: languagesIcon, title: 'Languages', class: 'languages', text: 'Multi-languages, creating ease of use'},
    {id: '5', icon: customisationIcon, title: 'Customise', class: 'customisation', text: 'Enhance your business requirements'},
];

const GreySixFeatures = () => {
    return (
        <Wrapper>
            <section className="section-white section_second">
                <article className="container">
                    <div className="section-title text-centered">
                        <h2>Get Payments<span>.</span> Faster<span>.</span> Safer<span>.</span></h2>
                        <p>More tools. More power. More performance.</p>
                    </div>
                    <div className="row cards-container">
                        {list.map(feature => (
                            <div className="col-sm-4 col-6" key={feature.id}>
                                <div className={`text-center cards ${feature.class}`}>
                                    <div className="card-container">
                                        <img className={`card-image ${feature.class}`} src={feature.icon} alt="Card image" />
                                    </div>
                                    <div className="card-body">
                                        <h5 className="card-title">{feature.title }</h5>
                                        <p className="card-text">{feature.text }</p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </article>
            </section>
        </Wrapper>
    );
}

export default GreySixFeatures;
