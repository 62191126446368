import React from 'react'
import styled, { css } from 'styled-components'

const Wrapper = styled(({ gateway, ...props}) => <section {...props} />)`
  padding: 80px 0 170px;
  background: ${props => props.theme.colors.white};
  .container-wrapper {
    background: ${props => props.theme.colors.white};
  }
  p {
    color: ${props => props.gateway ? props.theme.colors.blue : props.theme.colors.black};
  }
  .mark-container {
    padding-left: 15px;
    .mark1 {
      display:inline-block; background-color: #4130f9; width: 7px; height: 44px;  transform: rotate(45deg); 
    }
    .mark2 {
      display:inline-block;background-color: #4130f9; margin-left: 7px; width: 7px; height: 44px;  transform: rotate(45deg); 
    }
  }
  .founder-description{
    margin-top: 70px;
  }
  .founder-narrative {
    font-size: 24px;
    font-weight: normal;
    margin-bottom: 23px;
    line-height: ${props => props.theme.type.lineHeights.bigger};
  }
  .founder-name, .founder-position {
    font-size: 21px;
  }
  .founder-name {
    font-weight: bold;
    color: ${props => props.theme.colors.aquaBlue};
    margin-bottom: 0px;
  }
 
  .founder-image {
    text-align: right;
    img {
      width: 500px;
      height: 500px;
    }
  }

  .tealNicola {
    p.founder-name {
      color: ${props => props.theme.colors.aquaBlue};
    }
    .testimonial-icon {
      padding: 0 0 9px;
    }
    .founder-name {
      font-weight: bold;
      color: $aquaBlue;
    }
  }

  .founder-narrative {
    font-size: 24px;
    font-weight: normal;
    margin-bottom: 23px;
    line-height: 1.17;
  }
  .founder-name, .founder-position {
    font-size: 21px;
  }
  .founder-name {
    font-weight: bold;
    margin-bottom: 0px;
    line-height: 1.17;
  }

  @media (max-width: ${props => props.theme.responsive.large}) {
    padding: 40px 0 170px;
    .founder-description {
      margin-top: 20px;
      p {
        font-size: ${props => props.theme.type.sizes.medium};
      }
    }
  }

  @media (max-width: ${props => props.theme.responsive.small}) {
    background: ${props => props.theme.colors.white};
    margin: 0px 0 30px;
    padding: 50px 0 0px;
    .founder-description {
      padding-top: 48px;
      p{
        font-size: ${props => props.theme.type.sizes.base};
      }
    }
    .founder-image {
      padding: 0px;
      img{
        width: 100%;
        max-width: 100%;
        height: auto;
        padding: 0 30px;
      }
    }
    .founder-narrative, .founder-position, .founder-name {
      font-size: 16px;
    }
    .founder-narrative {
      line-height: 20px;
      letter-spacing: 0.2px;
    }
    .founder-description {
      padding: 0px 30px 0 30px;
      margin-top: 30px;
      line-height: 1.25;
      letter-spacing: 0.19px;      
    }
    .founder-name {
      margin-top: 15px;
      margin-bottom: 10px;
    }
    p {
      margin-bottom: 16px;
    }
  }
`

export {Wrapper}
