import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import {Wrapper} from './styles'

import nicola from '../../images/Nicola.png'
import nicolaGateway from '../../images/NicolaRizz.png'
import testimonialIcon from '../../images/testimonial-icon.svg'

const NicolaCard = ({gateway, data: { node: testimonial }}) => {
  return (
    <Wrapper gateway={gateway}>
      <div className="container-wrapper">
        <article className={`container ${gateway ? 'tealNicola' : ''}`}>
          <div className="row">
            <div className="col-md-6 col-sm-12 order-2 order-md-1 founder-description">
              {gateway ?
                <div className="mark-container">
                  <div className="mark1"/>
                  <div className="mark2"/>
                </div>
                :
                <div className="testimonial-icon">
                  <img src={testimonialIcon} alt="Testimonial"/>
                </div>
              }
              <p className="founder-narrative">
                {/*{testimonial.text.childMarkdownRemark.excerpt}*/}
                Netstorming have been extremely happy working with Zoopay. They understand the needs of the Travel Industry and offer excellent solutions with a good selection of Products. Using their technology and knowledge of payments, makes the integration process simple and easy.
              </p>
              <p className="founder-name">Nicola Rizzardini</p>
              <p className="founder-position">CEO & Founder @ Netstorming</p>
            </div>
            <div className="col-md-6 col-sm-12 order-1 order-md-2 founder-image">
              {!gateway ?
                <img src={nicola} alt="Nicola Rizzardini" />
                :
                <img src={nicolaGateway} alt="Nicola Rizzardini" />
              }
            </div>
          </div>
        </article>
      </div>
    </Wrapper>
  )
}

const Nicola = ({gateway}) => (
  <StaticQuery
    query={graphql`
      query {
        allContentfulTestimonial {
          edges {
            node {
              id
              name
              position
              text {
                childMarkdownRemark {
                  excerpt(pruneLength: 500)
                }
              }
            }
          }
        }
      }
    `}
    render={data => {
      const list = data.allContentfulTestimonial.edges
      if (!list.length) {
        return <div />
      }

      const randNum = Math.round(Math.random() * (list.length - 1))
      return <NicolaCard data={list[randNum]} gateway={gateway} />
    }}
  />
)

export default Nicola
