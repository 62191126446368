import styled from 'styled-components'

const Wrapper = styled.section`
  h2 {
    color: ${props => props.theme.colors.blue};
    span {
      color: ${props => props.theme.colors.aquaBlue};
    }
  }
  margin-top: 74px;
  .row {
    margin-top: 20px;
    &.cards-container {
      .cards{
        margin: 60px 0 0 0;
        .card-image {}
      }
    }
  }
  .card-body {
    margin-top: 12px;
    .card-title {
      font-size: 30px;
      font-weight: bold;
      color: ${props => props.theme.colors.blue};
    }
    .card-text {
      font-size: 18px;
      font-weight: normal;
      color: ${props => props.theme.colors.blue};
      span {
        font-size: 30px;
        line-height: 20px;
        color: ${props => props.theme.colors.aquaBlue};
      }
    }
  }
  .tealFourFeatures {
    h2, .card-body .card-title, .card-body .card-text {
      color: ${props => props.theme.colors.deepButtonBlue};
    }
  }

  @media (max-width: ${props => props.theme.responsive.small}) {
    margin-top: 0px;
    padding: 60px 0 0;
    img {
      margin: 20px 0 0 0;
    }
    .section-title p {
      line-height: 1.25;
    }
    h2 {
      margin: 0 0 35px;
    }
    .row {
      margin-top: 0px;
      &.cards-container {
        .cards {
          margin: -10px 0 0 0;
        }
        .card-image {
          max-width: 100%;
          height: auto;
        }
        .card-body {
          margin-top: 0px; 
          padding: 0 0 20px;
          .card-title {
            font-size: 20px;
            font-weight: bold;
            padding: 0 30px;
          }
          .card-text {
            font-size: 16px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.25;
            letter-spacing: 0.19px;
            padding: 0 15px;
          }
        }
      }
    }
  }
`;

export {Wrapper}
