import React from 'react'
import styled, { css } from 'styled-components'

const Wrapper = styled(({ gateway, ...props }) => <section {...props} />)`
  
  ${props =>
    props.gateway
      ? css`
          background: ${props => props.theme.colors.deepButtonBlue};
        `
      : css`
          background: ${props => props.theme.colors.blue};
        `
  }
  margin-top: 150px;
  padding-top: 68px;
  padding-bottom: 80px;
  p {
    color: ${props => props.theme.colors.white};
  }
  h2 {
    color: ${props => props.theme.colors.white};
    span {
      color: ${props => props.theme.colors.aquaBlue};
    }
  }
  &.section-blue .custom-padding-xs p {
    font-size: 18px;
    font-weight: normal;
  }
  .button-block {
    margin-top: 24px;
  }

  @media (max-width: ${props => props.theme.responsive.small}) {
    margin-top: 73px;
    padding: 40px 18px;
    h2 {
    font-size: 20px;
    letter-spacing: 0.25px;
    }
    .button-block {
    margin-top: 11px;
    }
    .custom-padding-xs {
      padding: 0 15px;
      p{
        font-size: 16px;
        line-height: 20px;
        margin: 0 0 15px 0px;
      }
    }
  }
`

export {Wrapper} 
