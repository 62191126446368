import React from 'react'
import { Link } from 'gatsby'
import { Wrapper } from './styles'

import heroBlueImg from '../../images/hero.gif'
import heroBlueImgMobile from '../../images/hero-mobile.gif'
import heroTealImg from '../../images/heroTeal.gif'
import heroTealImgMobile from '../../images/heroTeal-mobile.gif'

const BlueHero = ({ teal }) => {
  return (
    <Wrapper teal={teal}>
        <section className="section-blue">
            <article className="container">
                <div className="row">
                    { !teal ? 
                        <div className="col-xl-7 custom-padding-xs">
                            <h1>Payments make<br /> your world go round<span>.</span></h1>
                            <p>The partner to help you increase conversion<br />with our global reach and acquiring network</p>
                            <div>
                                <Link className="btn btn-primary aqua" to="/contact">Try Demo</Link>
                            </div>
                        </div>
                        :
                        <div className="col-xl-7 custom-padding-xs">
                            <h1>Travel Industry Risk Management<span className="aqua-blue">.</span> <br />
                            Made Simple<span className="aqua-blue">.</span></h1>
                            <p>The technology you need to solve all <br />your currency and payment challenges.</p>
                            <div>
                                <Link className="btn btn-primary aqua" to="/demo">Try Demo</Link>
                            </div>
                        </div>
                    }
                    <div className="col-xl-5 center-align hero-container">
                        { !teal ? <img className="hero-image" src={heroBlueImg} alt="hero-image" />
                            : <img className="hero-image" src={heroTealImg} alt="hero-image" />
                        }
                        { !teal ? <img className="hero-image-mobile" src={heroBlueImgMobile} alt="hero-image" />
                            : <img className="hero-image-mobile" src={heroTealImgMobile} alt="hero-image" />
                        }
                    </div>
                </div>
            </article>
        </section>
    </Wrapper>
  )
}

export default BlueHero
