import React from 'react'

import {Wrapper} from './styles'

import visa from '../../images/visa-icon.svg' 
import mastercard from '../../images/mastercard-icon.svg' 
import americanExpress from '../../images/american-express-icon.svg' 
import unionPay from '../../images/union-pay-icon.png' 
import dinersClub from '../../images/diners-icon.svg' 
import discover from '../../images/discover-icon.svg' 
import jcb from '../../images/jcb-icon.png' 
import alternativePayments from '../../images/alternative-payments-icon.svg' 
const list = [
    {id: '0', icon: visa, title: 'Visa'},
    {id: '1', icon: mastercard, title: 'Mastercard'},
    {id: '2', icon: americanExpress, title: 'American Express'},
    {id: '3', icon: unionPay, title: 'Union Pay', srcsetUP: 'srcset="img/union-pay-icon@2x.png 2x, img/union-pay-icon@3x.png 3x"'},
    {id: '4', icon: dinersClub, title: 'Diners Club'},
    {id: '5', icon: discover, title: 'Discover'},
    {id: '6', icon: jcb, title: 'JCB', srcsetJcb: 'srcset="img/jcb-icon@2x.jpg 2x, img/jcb-icon@3x.jpg 3x"'},
    {id: '7', icon: alternativePayments, title: '+50 alternative payment methods'},
]

const WhiteEightFeatures = () => {
    return (
        <Wrapper>
            <section className="section-white section_fifth">
                <article className="container">
                    <div className="section-title text-centered">
                        <h2>Worldwide Coverage<span>.</span></h2>
                        <p>It doesn't matter where your clients are based,<br />
                            we allow them to pay their way</p>
                    </div>
                    <div className="row payments-block">
                        {list.map(feature => (
                            <div className="col-sm-3 col-6 text-center payment-item" key={feature.id}>
                                <img src={feature.icon} alt={feature.title}/>
                                <h5>{feature.title}</h5>
                            </div>
                        ))}
                    </div>
                </article>
            </section>
        </Wrapper>
    );
}

export default WhiteEightFeatures
