import React from 'react'

import Layout from '../components/Layout'
import SEO from '../components/SEO'
import SEOPageTitle from '../components/SEOPageTitle'
import BlueHero from '../components/BlueHero'
import WhiteThreeFeatures from '../components/WhiteThreeFeatures'
import GreySixFeatures from '../components/GreySixFeatures'
import WhiteFourFeatures from '../components/WhiteFourFeatures'
import BlueCallExpert from '../components/BlueCallExpert'
import WhiteEightFeatures from '../components/WhiteEightFeatures'
import Nicola from '../components/Nicola'

const Home = ({ data }) => {
  const seoNode = {
    title: 'Zoopay - Gateway',
    description:
      'Access multi-currency processing. Process all multi-currency cards and revert over 100 currencies.',
  }

  return (
    <Layout gateway>
      <BlueHero />
      <WhiteThreeFeatures />
      <GreySixFeatures />
      <WhiteFourFeatures />
      <BlueCallExpert />
      <WhiteEightFeatures />
      <Nicola gateway />
      <SEOPageTitle metaTitle={seoNode.title} />
      <SEO postNode={seoNode} pagePath="" customTitle customDescription />
    </Layout>
  )
}

export default Home
