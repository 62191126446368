import React from 'react'
import {Wrapper} from './styles'

import globalBlueIcon from '../../images/global-icon.svg'
import smartIcon from '../../images/smart-icon.svg'
import secureIcon from '../../images/Secure.png'

import simpleIcon from '../../images/simple.png'
import competitiveIcon from '../../images/competitive.png'
import globalIcon from '../../images/global.png'

const list = [
  {id: '0', icon: globalBlueIcon, title: 'Global', class: 'global', text: 'Flexible, adaptable and scalable. Grow rapidly and securely, while enjoying higher conversion through local processing.'},
  {id: '1', icon: smartIcon, title: 'Smart', class: 'smart', text: 'Smart, single solution, takes care of all your payment processing, which reduces risk and maximises profits.'},
  {id: '2', icon: secureIcon, title: 'Secure', class: 'secure', text: 'Advanced fraud technology and customisable risk settings, ensure freedom from worry and stress.'}
]

const tealList = [
  {id: '0', icon: simpleIcon, title: 'Simple', class: 'simple', text: 'ARCH complete suite of services can be modified to meet your needs, therefore creating ease of use across your treasury systems.'},
  {id: '1', icon: competitiveIcon, title: 'Competitive', class: 'competitive', text: 'ARCH allows you to buy or sell in multiple currencies, whilst also helping you safeguard your profits.'},
  {id: '2', icon: globalIcon, title: 'Global', class: 'global', text: 'Stay connected with one platform across all companies global locations.'}
]

const WhiteThreeFeatures = ({ teal }) => {
  return (
    <Wrapper teal={teal}>
        <article className={`container ${teal ? 'tealThreeFeatures' : ''}`}>
            {!teal ?
                <div className="section-title text-centered">
                    <h2>Go Global<span>.</span> Be Smart<span>.</span><br />Stay Secure<span>.</span></h2>
                </div>
            : '' 
            }                
            <div className="row">
                {!teal ?
                    list.map(feature => (
                        <div className="col-sm" key={feature.id}>
                            <div className="card-body">
                                <img className={`card-image-top ${feature.class}`}  src={feature.icon} />
                                <h5 className="card-title">{feature.title}</h5>
                                <p className="card-text">{feature.text}</p>
                            </div>
                        </div>
                        )
                    )
                :
                    tealList.map(feature => (
                        <div className="col-sm" key={feature.id}>
                            <div className="card-body">
                                <img className={`card-image-top ${feature.class}`}  src={feature.icon} />
                                <h5 className="card-title">{feature.title}</h5>
                                <p className="card-text">{feature.text}</p>
                            </div>
                        </div>
                        )
                    )
                }
            </div>
        </article>
    </Wrapper>
  )
}

{/* <section className="section-white section_in-middle mid-icons"> */}

export default WhiteThreeFeatures
