import styled from 'styled-components'

const Wrapper = styled.section`
  margin: 0px auto 90px;
  color: ${props => props.theme.colors.blue};
  p, h5 {
    color: ${props => props.theme.colors.blue};
  }
  h2 {
    text-align: center;
  }
  background-color: ${props => props.theme.colors.white};
  padding-top: 121px;
  .row {
    margin-top: 51px;
  }
  .card-body {
    padding: 0;
    .card-image-top{
      max-width: 80px;
    }
    .card-title {
      margin-top: 32px;
      font-size: 24px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.04;
      letter-spacing: 0.29px;
      margin-bottom: 20px;
    }
    .card-text {
      line-height: 22px;
      font-size: 18px;
    }
  }
  .tealThreeFeatures {
    p, h5 {
      color: ${props => props.theme.colors.deepButtonBlue};
    }
  }


  @media (max-width: ${props => props.theme.responsive.small}) {
    padding-top: 47px;
    margin: 0;
    .row {
      margin-top: 37px;
    }
    h2 {
      padding: 0 15px;
    }
    .card-body {
      padding: 0 15px 45px;
      .card-text {
        line-height: 1.25;
        font-size: 16px;
      }
      .card-title {
        margin-top: 24px;
        margin-bottom: 8px;
      }
      .card-image-top {
        max-width: 60px
      }
    }
  }

`

export { Wrapper }
