import styled from 'styled-components'

const Wrapper = styled.section`
  margin: 115px 0 88px 0;
  p, h2, h5 {
    color: ${props => props.theme.colors.blue};
  }
  .section-title {
    p {
      font-size: 22px;
    }
  }
  .payments-block {
    margin-top: 20px;
    .payment-item {
      padding-top: 62px;
      img {
        width:70px;
      }
    }
    h5 {
      margin-top: 25px;
      font-size: 18px;
      font-weight: bold;
    }
  }
  @media (max-width: ${props => props.theme.responsive.small}) {
    .payments-block {
      margin-top: -2px;
      .payment-item {
        padding-top: 25px;
        img {
          width:52px;
        }
        h5 {
          font-size: 16px;
          font-weight: normal;
        }
      }
    }
  }
`

export {Wrapper}
